import React from 'react';
import {Dialog, Typography, Button, Box, DialogActions} from '@mui/material'
import FrameworkForm from '../../../Framework/Form';
import FilterSection from './Section';

export default function FilterDialog(props) {
  return (
    <Dialog
      open={props.filterDialogOpen}
      onClose={props.handleFilterDialogToggle}>
      <FrameworkForm
        panels={[
          <Typography variant="h6">
            Filters
          </Typography>,
          <FilterSection 
            title="Medium" 
            filterType="filterMediums"
            filterState={props.potentialFilterState.filterMediums} 
            filterOptions={props.filterOptions.filterMediums}
            handleFilterChange={props.handleFilterChange}/>,
          <FilterSection 
            title="Year" 
            filterType="filterYears" 
            filterState={props.potentialFilterState.filterYears}
            filterOptions={props.filterOptions.filterYears}
            handleFilterChange={props.handleFilterChange}/>
        ]}
      />
      <DialogActions>
        <Box style={{display:"flex", width:"100%"}}>
          <Button variant="contained" 
            color="primary" 
            fullWidth 
            style={{marginRight:"2px"}} 
            onClick={props.handleFilterApply}>
            Apply
          </Button>
          <Button variant="contained" 
            color="secondary" 
            fullWidth 
            style={{marginLeft:"2px"}} 
            onClick={props.handleFilterClear}>
            Clear
          </Button>
        </Box>
      </DialogActions>
  </Dialog>  
  )
}