import React from "react";
import {
  Typography,
  Card,
  CardMedia,
  Button,
  ButtonGroup,
} from "@mui/material";
import { AddShoppingCart, Info } from "@mui/icons-material/";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

export default function ShopPopperItem(props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const handleAddToCart = (event) => {
    event.stopPropagation();
    if(props.product.dynamicItem){
      props.handleAddToCart({...props.product, option: props.imageId}, 1, (cartResponse) => {
        enqueueSnackbar(cartResponse.message, cartResponse.options);
      });
    }else{
      props.handleAddToCart(props.product, 1, (cartResponse) => {
        enqueueSnackbar(cartResponse.message, cartResponse.options);
      });
    }

  };

  function singularize(word) {
    const endings = {
      ves: "fe",
      ies: "y",
      i: "us",
      zes: "ze",
      ses: "s",
      es: "e",
      s: "",
    };
    return word.replace(
      new RegExp(`(${Object.keys(endings).join("|")})$`),
      (r) => endings[r]
    );
  }
  return (
    <Card
      key={props.product._id}
      sx={{ display: "flex", maxHeight: "120px" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <CardMedia
            sx={{ objectFit: "contain" }}
            component="img"
            image={
              process.env.REACT_APP_API_URL +
              "/images/storeimages/" +
              props.product._id +
              "/" +
              props.product.productImageIds[0] +
              "/catalog.png"
            }
            alt={props.product.name}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          {props.product.limitedEdition ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                style={{
                  paddingLeft: "10px",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  backgroundImage:
                    "linear-gradient(to left,  #6ec81d, #006fd3, #5400ac, #b40983, #e73131, #ff9420",
                }}
              >
                {"LIMITED EDITION"}
              </Typography>
            </div>
          ) : (
            <div style={{ height: "24px" }} />
          )}
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle1">
              {props.product.widthIN +
                '"x' +
                props.product.lengthIN +
                '"'}
            </Typography>
            <Typography variant="subtitle1">
              {singularize(props.product.category.name).toUpperCase()}
            </Typography>
          </div>

          <Typography variant="h3">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.product.priceUSD / 100)}
          </Typography>
        </div>

        {/* <Typography variant="h5" style={{paddingLeft:"10px"}}>
              {props.product.name.toUpperCase()}
            </Typography>*/}

        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <ButtonGroup
            variant="contained"
            orientation="vertical"
            fullWidth
            style={{ height: "100%" }}
          >
            <Button
              style={{
                display: "flex",
                height: "50%",
                borderTopLeftRadius: "0px",
              }}
              onClick={handleAddToCart}
            >
              <AddShoppingCart />
            </Button>
            <Button
              style={{
                display: "flex",
                height: "50%",
                borderBottomLeftRadius: "0px",
              }}
              onClick={(e) => {
                (props.product.dynamicItem)
                ?navigate("/shop/item/" + props.product._id +"?option="+props.imageId)
                :navigate("/shop/item/" + props.product._id)
              }}
            >
              <Info />
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </Card>
  );
}
