import React from 'react';
import AppBarSmall from './AppBarSmall.js';
import AppBarLarge from './AppBarLarge.js';
import { useIsLarge } from './IsLargeContext.js';
export default function AppBar(props) {
  const isLarge = useIsLarge()
  return (
    (isLarge)
    ?<AppBarLarge numberOfItemsInCart={props.numberOfItemsInCart}/>
    :<AppBarSmall numberOfItemsInCart={props.numberOfItemsInCart} toggleDrawer={props.toggleDrawer}/>  
  );
}