import React, { createContext, useContext, useState } from "react";
import { useMediaQuery } from "@mui/material";

const IsLargeContext = createContext(null);

export function IsLargeProvider({ children }) {
  const isLarge = useMediaQuery("(min-width:750px)");
  return (
    <IsLargeContext.Provider value={isLarge}>
      {children}
    </IsLargeContext.Provider>
  );
}

export function useIsLarge() {
  return useContext(IsLargeContext);
}
