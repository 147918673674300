import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
const helmetMap = {
  "/": {
    title: "Error By Trial - The Art of Woodie Tatz-Morey.",
    description:
      "Explore the captivating art of Woodie Tatz-Morey at Error By Trial. Discover a gallery of digital, acrylic and mixed media designs that are unlike anything else. Find unique and exclusive products featuring Woodie's fractal art in our shop. Learn more about the artist and the Error By Trial brand on our about page.",
    keywords:
      "Error By Trial, Woodie Tatz-Morey, Art, Digital, Acrylic, Mixed Media, Fractal, Gallery, Shop, About",
  },
  "/shop": {
    title:
      "Shop Exclusive Products Featuring Woodie Tatz-Morey's Art at Error By Trial.",
    description:
      "Find the perfect piece of art to take home with you from Error By Trial. Shop our exclusive collection of products featuring Woodie Tatz-Morey's one-of-a-kind fractal art designs, including prints and original paintings. From buttons to postcards, find the perfect item to showcase your love for Error By Trial's digital, acrylic, and mixed media art. Start shopping now.",
    keywords:
      "Error By Trial, Woodie Tatz-Morey, Art, Digital, Acrylic, Mixed Media, Fractal, Shop, Products, Prints, Original Paintings",
  },
  "/about": {
    title:
      "Get to Know Woodie Tatz-Morey and Error By Trial - The Story Behind the Art",
    description:
      "Get to know the artist behind Error By Trial - Woodie Tatz-Morey. Discover the story of the company and its unique digital, acrylic, and mixed media art designs. Learn about Woodie's creative process and see how Error By Trial has evolved over the years. Explore the about page now for a deeper understanding of the art and artist behind Error By Trial.",
    keywords:
      "Error By Trial, Woodie Tatz-Morey, Art, Digital, Acrylic, Mixed Media, Fractal, About, Artist, Company, Story",
  },
  "/gallery": {
    title: "Explore the Art of Woodie Tatz-Morey at Error By Trial Gallery.",
    description:
      "Discover the mesmerizing art of Woodie Tatz-Morey at Error By Trial.  Browse our gallery featuring a breathtaking collection of unique digital, acrylic, and mixed media designs. Get a glimpse into the imaginative world of this talented artist and see the evolution of Error By Trial's one-of-a-king art. Start your journey now.",
    keywords:
      "Error By Trial, Woodie Tatz-Morey, Art, Digital, Acrylic, Mixed Media, Fractal, Gallery",
  },
  "/policies": {
    title: "Error By Trial Policies - Shipping, Returns, Privacy, and Terms",
    description:
      "Read the Error By Trial policies for information on shipping, returns, privacy, and terms. We are committed to ensuring our customers have a positive experience and feel confident in their purchases.",
    keywords:
      "shipping policies, return policies, privacy policy, terms and conditions, Error By Trial policies",
  },
  "/admin": {
    title: "EBTADMIN",
    description: "EBT Admin",
    keywords: "",
  },
};
export default function HelmetRack(props) {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const basePath = `/${pathnames.slice(0, 1).join("/")}`;
  const metaData = helmetMap[basePath] ? helmetMap[basePath] : helmetMap["/"];

  return (
    <Helmet>
      <title>{metaData.title}</title>
      <meta name="description" content={metaData.description} />
      <meta name="keywords" content={metaData.keywords} />
    </Helmet>
  );
}
