import React from "react";
import { Typography, Box, CardMedia, Stack, Paper } from "@mui/material";
import { useIsLarge } from "../Navigation/IsLargeContext.js";

export default function About(props) {
  const isLarge = useIsLarge();

  return (
    <Box
      style={{ display: "flex", justifyContent: "center", marginTop: "7px" }}
    >
      <Box
        raised
        style={{ marginRight: "10px", marginLeft: "10px", maxWidth: "750px" }}
      >
        <Stack spacing={0}>
          <Paper square style={{ borderRadius: "0px 50px 0px 0px" }}>
            <Box
              style={{
                margin: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="h2" color="text.primary">
                    {"Welcome"}
                  </Typography>
                  <Typography
                    variant={isLarge ? "body1" : "body2"}
                    color="text.secondary"
                  >
                    {
                      "Glad you made it.  My name is Woodie. On this page you can learn a little about who I am, what Error By Trial is, and find some info about the website as well. I intend to contine updating this information as time passes and it becomes more apperant what needs to be here. Reach out via "
                    }
                    <a href="mailto: contact@errorbytrial.com">
                      contact@errorbytrial.com
                    </a>
                    {" if you have any questions."}
                    <CardMedia
                      component="img"
                      style={{
                        margin: "5px",
                        objectFit: "contain",
                        height: "100%",
                        borderRadius: "30px 30px 30px 30px",
                      }}
                      src={
                        process.env.REACT_APP_API_URL +
                        "/images/siteimages/icesun.jpg"
                      }
                      alt={"imagiif"}
                    />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
          <Paper square style={{ borderRadius: "0px 0px 0px 0px" }}>
            <Box
              style={{
                margin: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row-reverse",
                }}
              >
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant="h2"
                    color="text.primary"
                    style={{ alignSelf: "flex-end" }}
                  >
                    {"About Me"}
                  </Typography>
                  <Typography
                    variant={isLarge ? "body1" : "body2"}
                    color="text.secondary"
                  >
                    <CardMedia
                      component="img"
                      style={{
                        maxWidth: "42%",
                        margin: "5px",
                        objectFit: "contain",
                        float: "left",
                        height: "100%",
                        borderRadius: "30px 0px 0px 0px",
                      }}
                      src={
                        process.env.REACT_APP_API_URL +
                        "/images/siteimages/imagoof.jpg"
                      }
                      alt={"imagiif"}
                    />
                    {
                      "As the type of person who really doesn’t enjoy defining myself, I have spent a lot of time trying to write this paragraph, but it always comes off as too much personal info or just too broad and rambling.  I know that I want to include that I am a lifelong Montanan.  It is easy to appreciate the beauty of nature here, and I love looking at the mountains and the sky.  I am also fascinated by computers and machines.  I find it really interesting how machines arise out of nature and are then used to emulate nature itself.  It was when I visited the renaissance fair in Moscow Idaho that I was inspired by an artist there to start using Ultra Fractal software.  I started sharing the images online and eventually printing them out and bringing them to local music and art festivals.  I’d like to do more in-person art vending, but I have also been spending a lot of time building this website to share and sell my art with even more people.  At the moment, I am still making a lot of images directly from ultra fractal but have also been working with acrylics and photography.  I am particularly interested in working more on combining the three together."
                    }
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
          <Paper square style={{ borderRadius: "0px 0px 0px 0px" }}>
            <Box
              style={{
                margin: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="h2" color="text.primary">
                    {"Error By Trial"}
                  </Typography>
                  <Typography
                    variant={isLarge ? "body1" : "body2"}
                    color="text.secondary"
                  >
                    <CardMedia
                      component="img"
                      style={{
                        maxWidth: isLarge ? "24%" : "42%",
                        margin: "5px",
                        float: "right",
                        objectFit: "contain",
                        height: "100%",
                        borderRadius: "0px 30px 0px 0px",
                      }}
                      src={
                        process.env.REACT_APP_API_URL +
                        "/images/siteimages/artsale.jpg"
                      }
                      alt={"imagiif"}
                    />
                    {
                      "I don’t remember exactly when I decided to start publishing under the name Error By Trial, because it was a long time ago.  I think I was sitting in a sauna though. I do know I started using the name as a play on the name of the traditional Trial and Error method.  Part of my thought was to point out the vital element of error in learning and creation.  Maybe the name will help someone reflect on the transitory nature of processes over time and the inevitable failure involved with progress. As a company, I hope Error By Trial can provide people with high quality and long lasting art to keep in their homes."
                    }
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
          <Paper square style={{ borderRadius: "0px 0px 0px 0px" }}>
            <Box
              style={{
                margin: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="h2" color="text.primary">
                    {"Website Info"}
                  </Typography>
                  <Typography
                    variant={isLarge ? "body1" : "body2"}
                    color="text.secondary"
                  >
                    {
                      "The creation of this website has been a large project for me. One that is still ongoing with no end in sight. I’ve been playing with website creation for a long time, but it was after I took a code bootcamp where I learned about React that I started working on what would be the first version of this website.  React really made creating a more complex and interactive website approachable for me.  The website is built with the MERN stack and utilizes Material UI for basic styling. If you run into any issues with the website or have any feedback, please let me know at "
                    }
                    <a href="mailto: contact@errorbytrial.com">
                      contact@errorbytrial.com
                    </a>
                    {
                      ". I would like for it to work well for you and learn from visitor experiences."
                    }
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Stack>
      </Box>
    </Box>
  );
}
