import React, { useEffect, useState, Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import { HelmetProvider } from "react-helmet-async";
import api from "../api";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import Footer from "../components/Navigation/Footer";
import Loading from "../components/Navigation/Loading";
import { AppBar, Gallery, MenuDrawer, About, Admin } from "../components";
import { IsLargeProvider } from "../components/Navigation/IsLargeContext";
import GalleryModal from "../components/Gallery/Modal";
import Landing from "../components/Landing";
import { loadStripe } from "@stripe/stripe-js/pure";
import HelmetRack from "../components/Framework/HelmetRack";
const Policies = lazy(() => import("../components/Policies"));
const AdminGallery = lazy(() => import("../components/Admin/Gallery"));
const AdminShop = lazy(() => import("../components/Admin/Shop"));
const ShopItemEdit = lazy(() =>
  import("../components/Admin/Shop/Item/ShopItemEdit")
);
const ShopCategoryAdd = lazy(() =>
  import("../components/Admin/Shop/Category/ShopCategoryAdd")
);
const GalleryMediumAdd = lazy(() =>
  import("../components/Admin/Gallery/Medium/GalleryMediumAdd")
);
const AdminGalleryImageDetail = lazy(() =>
  import("../components/Admin/Gallery/Item/Update")
);
const AdminGalleryImageAdd = lazy(() =>
  import("../components/Admin/Gallery/Item/Create")
);
const AdminGalleryMediumEdit = lazy(() =>
  import("../components/Admin/Gallery/Medium/MediumEdit")
);
const ShopCheckout = lazy(() => import("../components/Shop/Checkout"));
const ShopCatalogItemDetail = lazy(() => import("../components/Shop/Item"));
const ShopOrderSuccess = lazy(() => import("../components/Shop/Order"));
const ShopCatalog = lazy(() => import("../components/Shop/Catalog"));

const theme = createTheme({
  typography: {
    fontFamily: [
      "Teko",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      main: "#517693",
      contrastText: "#fff",
    },
    outlined: {
      main: "rgba(0, 0, 0, .6)",
      contrastText: "#fff",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        containerRoot: {
          fontFamily: "Teko",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#c0c0c0",
        },
      },
    },
  },
});

function App() {

  //Navigation
  const [mobileNavDrawerOpen, setMoblieNavDrawerOpen] = useState(false);

  //Cart
  const [cart, setCart] = useState([]);
  const [condensedCart, setCondensedCart] = useState([]);

  //App
  const [loggedIn, setLoggedIn] = useState(false);
  //******************Navigation*******************//
  const toggleDrawer = (open) => (event) => {
    setMoblieNavDrawerOpen(open);
  };

  //******************Cart*******************//
  const updateCart = (cartData) => {
    setCart(cartData.cart);
    setCondensedCart(cartData.condensedCart);
  };

  const handleAddToCart = async (item, quantity, cb) => {
    var response = await api.addItemToCart({ item: item, quantity: quantity });
    updateCart(response.data.cartData);
    if (typeof cb === "function") {
      cb({
        message: response.data.message,
        options: { variant: response.data.success ? "success" : "warning" },
      });
    }
  };

  const handleRemoveFromCart = async (item, quantity, cb) => {
    var response = await api.removeItemFromCart({
      item: item,
      quantity: quantity,
    });
    updateCart(response.data.cartData);
    if (typeof cb === "function") {
      cb({
        message: response.data.data.message,
        options: {
          variant: response.data.data.success ? "success" : "warning",
        },
      });
    }
  };

  const deleteFromCart = (itemId) => {
    var indexOfItemToRemove = condensedCart.findIndex((innerItem) => {
      return innerItem._id === itemId;
    });
    handleRemoveFromCart(
      condensedCart[indexOfItemToRemove],
      condensedCart[indexOfItemToRemove].quantity
    );
  };

  const handleCartQuantityChange = async (item, quantity) => {
    var response = await api.adjustItemQuantityInCart({
      item: item,
      quantity: quantity,
    });
    updateCart(response.data.cartData);
  };

  const checkout = async (address, cb) => {
    const stripeSession = await api.fetchCheckout({
      cart: cart,
      address: address,
    });
    if (stripeSession.data.success) {
      const sessionId = stripeSession.data.session.id;
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PK);
      const { error } = await stripe.redirectToCheckout({
        sessionId: sessionId,
      });

      if (error) {
        cb(error);
      } else {
        cb();
      }
      cb();
    } else {
      cb(stripeSession.data.verifications.delivery);
    }
  };
  //************************************************//

  useEffect(() => {
    const loadData = async () => {
      try {
        var response = await api.retrieveCart();
        updateCart(response.data.cartData);
      } catch (error) {
        console.log(error);
      }
    };
    loadData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <IsLargeProvider>
        <HelmetProvider>
          <HelmetRack />
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            autoHideDuration={777}
          >
            <AppBar
              toggleDrawer={toggleDrawer}
              numberOfItemsInCart={cart.length}
            />
            <MenuDrawer
              open={mobileNavDrawerOpen}
              toggleDrawer={toggleDrawer}
              loggedIn={loggedIn}
            />
            <div className="appBarSpacer" />
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/gallery" element={<Gallery />}>
                  <Route
                    path="image/:imageId"
                    element={<GalleryModal handleAddToCart={handleAddToCart} />}
                  />
                </Route>
                <Route path="/about" element={<About />} />
                <Route path="/policies" element={<Policies />} />
                <Route
                  path="/shop"
                  element={<ShopCatalog handleAddToCart={handleAddToCart} />}
                />
                <Route
                  path="/shop/catalog"
                  element={<ShopCatalog handleAddToCart={handleAddToCart} />}
                />
                <Route
                  path="/shop/item/:itemId"
                  element={
                    <ShopCatalogItemDetail handleAddToCart={handleAddToCart} />
                  }
                />
                <Route
                  path="/shop/checkout"
                  element={
                    <ShopCheckout
                      cart={cart}
                      condensedCart={condensedCart}
                      checkout={checkout}
                      removeFromCart={deleteFromCart}
                      handleRemoveFromCart={handleRemoveFromCart}
                      handleAddToCart={handleAddToCart}
                      handleCartQuantityChange={handleCartQuantityChange}
                    />
                  }
                />
                <Route
                  path="/shop/order/:sessionId"
                  element={<ShopOrderSuccess updateCart={updateCart} />}
                />
                <Route
                  path="/admin"
                  element={
                    <Admin loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
                  }
                >
                  <Route path="/admin/gallery" element={<AdminGallery />} />
                  <Route
                    path="/admin/gallery/image/:itemId"
                    element={<AdminGalleryImageDetail />}
                  />
                  <Route
                    path="/admin/gallery/medium/:mediumId"
                    element={<AdminGalleryMediumEdit />}
                  />
                  <Route
                    path="/admin/gallery/image/add"
                    element={<AdminGalleryImageAdd />}
                  />
                  <Route
                    path="/admin/gallery/medium/add"
                    element={<GalleryMediumAdd />}
                  />
                  <Route path="/admin/store" element={<AdminShop />} />
                  <Route
                    path="/admin/store/item/:itemId"
                    element={<ShopItemEdit />}
                  />
                  <Route
                    path="/admin/store/category/add"
                    element={<ShopCategoryAdd />}
                  />
                </Route>
              </Routes>
            </Suspense>
            <Footer />
          </SnackbarProvider>
        </HelmetProvider>
      </IsLargeProvider>
    </ThemeProvider>
  );
}

export default App;
