import React from "react";
import { Typography } from "@mui/material";
import PopperFrame from "./PopperFrame";
import ShopPopperItem from "./ShopPopperItem";

export default function ShopPopper(props) {
  return (
    <PopperFrame
      menuRef={props.menuRef}
      open={props.popperState === "shop"}
      sections={
        props.image.associatedProducts.length > 0 ||
        props.image.dynamicProducts.length > 0
          ? [
              <Typography
                variant="subtitle1"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                {"Available For Purchase"}
              </Typography>,
              ...props.image.associatedProducts
                .concat(props.image.dynamicProducts)
                .map((product) => {
                  return (
                    <ShopPopperItem
                      product={product}
                      imageId={props.image._id}
                      handleAddToCart={props.handleAddToCart}
                    />
                  );
                }),
            ]
          : [
              <Typography
                variant="subtitle1"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                {"No Products With This Image Available"}
              </Typography>,
            ]
      }
    />
  );
}
