import React, { useState, useEffect }  from "react";
import { useParams, useOutletContext, useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';
import arrowRightCircleOutline from '@iconify/icons-mdi/arrow-right-circle-outline';
import arrowLeftCircleOutline from '@iconify/icons-mdi/arrow-left-circle-outline';
import {Typography, Modal } from '@mui/material';
import api from '../../../api';
import axios from 'axios';
import gestureSwipe from '@iconify/icons-mdi/gesture-swipe';
import GalleryModalMenu from './Menu.js';
import GalleryModalImage from './Image.js';
import GalleryModalArrowSideBar from './ArrowSideBar';
import { useIsLarge } from '../../Navigation/IsLargeContext.js';

export default function GalleryModal(props) {
  const navigate = useNavigate();
  const isLarge = useIsLarge();
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(true);
  const {imageId} = useParams();
  const {mediumFilter, yearsFilter, handleCloseGalleryModal, handleOpenGalleryModal} = useOutletContext();

  var initialX = null;
  var initialY = null;

  function startTouch(e) {
    initialX = e.touches[0].clientX;
    initialY = e.touches[0].clientY;
  };

  function moveTouch(e) {
    if (initialX === null) {
      return;
    }

    if (initialY === null) {
      return;
    }

    const currentX = e.touches[0].clientX;
    const currentY = e.touches[0].clientY;

    const diffX = initialX - currentX;
    const diffY = initialY - currentY;

    if ((Math.abs(diffX) > Math.abs(diffY))) {
      //console.log(diffX)
      if(Math.abs(diffX)>8.8){
        if (diffX > 0 ) {
          changeImage("next")
        } else {
          changeImage("previous")
        }  
      }

    }

    initialX = null;
    initialY = null;
  };

  function changeImage(direction){
    setLoading(true);
    if(direction === "previous"){
      api.determinePreviousImage({imageId: imageId,           
        incomingFilter:{
        medium: mediumFilter,
        years: yearsFilter,
      },}).then(res => {
        if(res.data.previousImage === imageId){
          setLoading(false)
        }else{
          navigate("/gallery/image/"+res.data.previousImage);
        }    
      })
    }else{
      api.determineNextImage({imageId: imageId,           
        incomingFilter:{
        medium: mediumFilter,
        years: yearsFilter,
      },
}).then(res => {
        if(res.data.nextImage === imageId){
          setLoading(false)
        }else{
          navigate("/gallery/image/"+res.data.nextImage);
        }
      })
    }
  }

  
  useEffect(
    () => {
      const loadData = async () => {

        try {
          const response = await api.getGalleryImageById(imageId);
          const imagesPath = process.env.REACT_APP_API_URL+'/images/galleryimages/';
          response.data.data.src = imagesPath.concat(response.data.data._id+"/"+response.data.data._id+"_1080.jpg");
          handleOpenGalleryModal();
          setImage(response.data.data);
          setLoading(false);
          window.addEventListener("touchstart", startTouch, false);
          window.addEventListener("touchmove", moveTouch, false);
          
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log(`call was cancelled`);
          } else {
            throw error;
          }
        }
      };
      loadData();
      return function cleanup() {
       window.removeEventListener("touchstart", startTouch);
       window.removeEventListener("touchmove", moveTouch);
      }
    },[imageId]
  );

  return (
    (image)
    ?<Modal
      style={{display:'flex',alignItems:'center',justifyContent:'center', margin: "auto"}}
      open={true}
      onClose={handleCloseGalleryModal}>
      <div 
        style={{height: "100%", 
        width:"100%", 
        display: "flex", 
        flexDirection:"column", 
        backgroundColor:"#272727cc", 
        justifyContent:(isLarge)?"flex-start":"space-between"}}>
        <div style={{zIndex:"510"}}>
          <GalleryModalMenu 
            image={image} 
            handleAddToCart={props.handleAddToCart}
            handleCloseGalleryModal={handleCloseGalleryModal} 
            />
        </div>
        <div 
          style={{marginTop: 'auto', 
          marginBottom:"auto", 
          minHeight:"0",
          height:"100%", 
          width: "100%",
          display:"flex", 
          alignItems:'center', 
          justifyContent:(isLarge)?"space-between":"space-around", 
          flexDirection:(isLarge)?"row":"column"}}>

          {(isLarge)
          ?<GalleryModalArrowSideBar direction="previous" changeImage={changeImage} icon={arrowLeftCircleOutline}/>
          :null}      
          <GalleryModalImage image={image} loading={loading}/>
          {(isLarge)
          ?<GalleryModalArrowSideBar direction="next" changeImage={changeImage} icon={arrowRightCircleOutline}/>
          :null}  

        </div>
      {(isLarge)
      ?null
      :<div style={{display: "flex", width: "100%", height: "36px", justifyContent:"center", marginBottom:"5px"}}>
        <Icon icon={gestureSwipe} width="32" height="32" style={{color:"white"}}/> 
        <Typography variant="h5" style={{marginLeft: "24px", color:"white"}}>
          Swipe To Browse
        </Typography >
      </div>}
      <div style={{position:"absolute",height: "100%", width: "100%", zIndex:'505'}} onClick={handleCloseGalleryModal}/>
      </div>
    </Modal>
    :null
  )
}