import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Stack,
  Pagination,
} from "@mui/material";

export default function PaginationFooter(props) {
  const { totalItems, limit, page, handleSetLimit, handleSetPage } = props;

  return Math.ceil(totalItems / limit) > 1 ? (
    <Stack spacing={2} alignItems={"center"} style={{ marginTop: "5px" }}>
      <FormControl style={{ maxWidth: "50%" }}>
        <Select
          size="small"
          value={limit}
          onChange={(e) => {
            handleSetLimit(e.target.value);
          }}
        >
          <MenuItem value={3}>Show 3 per page</MenuItem>
          <MenuItem value={6}>Show 6 per page</MenuItem>
          <MenuItem value={12}>Show 12 per page</MenuItem>
          <MenuItem value={24}>Show 24 per page</MenuItem>
          <MenuItem value={48}>Show 48 per page</MenuItem>
        </Select>
      </FormControl>
      <Pagination
        count={Math.ceil(totalItems / limit)}
        page={parseInt(page)}
        onChange={(e, value) => {
          handleSetPage(value);
        }}
      />
    </Stack>
  ) : null;
}
