import React from "react";
import { Icon } from '@iconify/react';
import { IconButton } from '@mui/material';

export default function GalleryModalArrowSideBar(props) {
  return (
    <div style={{display:"flex", flexDirection:"column", justifyContent:"center", height: "100%"}}>
      <IconButton  onClick={()=>{props.changeImage(props.direction)}} color="inherit" 
      style={{margin:"10px", backgroundColor: "rgba(0, 0, 0, 0.1)", zIndex:"510"}}>
        <Icon icon={props.icon} width="42" height="42" style={{color:"white"}}/>
      </IconButton>
    </div>
  )
}