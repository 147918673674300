import React from "react";
import { Popper, Box, Card, Stack, Divider } from "@mui/material";

export default function PopperFrame(props) {
  return (
    <Popper
      open={props.open}
      placement="bottom-start"
      anchorEl={props.menuRef.current}
      style={{ zIndex: "1350", width: "100%" }}
    >
      <Card
        sx={{
          zIndex: "515",
          padding: "10px",
          backgroundColor: "gainsboro",
          display: "flex",
          justifyContent: "center",
        }}
        square
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            maxWidth: "1440px",
            width: "100%",
          }}
        >
          <Box
            style={{
              margin: "0px 20px 0px 20px",
              minWidth: "320px",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack
              spacing={1}
              style={{ width: "100%" }}
              divider={<Divider style={{ width: "100%" }} />}
            >
              {props.sections
                .filter((section) => {
                  return section !== null;
                })
                .map((section, i) => {
                  return <div key={"menu-section-" + i}>{section}</div>;
                })}
            </Stack>
          </Box>
        </Box>
      </Card>
    </Popper>
  );
}
