import React from "react";
import { Typography, Paper } from "@mui/material";
import { Link } from "react-router-dom";

export default function Footer(props) {
  return (
    <Paper
      square
      elevation={0}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "inherit",
        marginTop: "15px",
      }}
    >
      <Link to="/policies" style={{ color: "white" }}>
        <Typography variant="body1" style={{ color: "black" }}>
          Terms and Policies
        </Typography>
      </Link>
      <Typography variamt="caption" style={{ color: "black" }}>
        Ⓒ Error By Trial. All rights reserved.
      </Typography>
    </Paper>
  );
}
