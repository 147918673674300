import React from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Button,
  Toolbar,
  Grid,
  AppBar,
  Badge,
} from "@mui/material";
import { ShoppingCartRounded } from "@mui/icons-material";

export default function AppBarLarge(props) {
  return (
    <AppBar position="fixed" className="appBar">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "1440px",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Toolbar style={{ minHeight: "64px", maxWidth: "1440px" }}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item xs={3}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  wrap="nowrap"
                >
                  <Grid item>
                    <Link to="/" style={{ color: "white" }}>
                      <Typography variant={"h4"}>Error By Trial</Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Link to="/gallery">
                      <Button className="appBarButton">GALLERY</Button>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to="/shop">
                      <Button className="appBarButton">SHOP</Button>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to="/about">
                      <Button className="appBarButton">ABOUT</Button>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  wrap="nowrap"
                >
                  <Link to="/shop/checkout" style={{ color: "white" }}>
                    <Badge
                      badgeContent={props.numberOfItemsInCart}
                      overlap="circular"
                      showZero
                      sx={{
                        "& .MuiBadge-badge": {
                          right: -3,
                          top: 13,
                          border: `2px solid white`,
                          padding: "0 4px",
                        },
                      }}
                    >
                      <ShoppingCartRounded />
                    </Badge>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </div>
      </div>
    </AppBar>
  );
}
