import React from "react";
import { Typography } from "@mui/material";
import { Twitter } from "@mui/icons-material/";
import PopperFrame from "./PopperFrame";

export default function InfoPopper(props) {
  return (
    <PopperFrame
      menuRef={props.menuRef}
      open={props.popperState === "info"}
      sections={[
        <Typography
          variant="subtitle1"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Typography variant="caption">{"Date"}</Typography>
          {new Date(props.image.date).toDateString()}
        </Typography>,
        <Typography
          variant="subtitle1"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Typography variant="caption">{"Medium"}</Typography>
          {props.image.medium.name}
        </Typography>,
        props.image.associatedTweet ? (
          <div>
            <Typography
              variant="subtitle1"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="caption">{"Links"}</Typography>
              <a
                href={
                  "https://twitter.com/errorbytrial/status/" +
                  props.image.associatedTweet
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter style={{ color: "#1DA1F2" }} />
              </a>
            </Typography>
          </div>
        ) : null,
        props.image.colors.length > 0 ? (
          <div>
            <Typography
              variant="subtitle1"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="caption">{"Color Analysis"}</Typography>
              <div style={{ display: "flex" }}>
                {props.image.colors.map((color) => {
                  return (
                    <div
                      key={color}
                      style={{
                        margin: "2px",
                        backgroundColor: color,
                        width: "15px",
                        height: "15px",
                        borderRadius: "10px",
                      }}
                    ></div>
                  );
                })}
              </div>
            </Typography>
          </div>
        ) : null,
      ]}
    />
  );
}
