export default {
  backgroundLoad: false,
    firstLoadComplete: false,
    images: [],
    totalImageCount: 0,
    loading: true,
    page: 1,
    limit: 12,
    filterState: {
      filterMediums: [],
      filterYears: [],
    },
    potentialFilterState: {
      filterMediums: [],
      filterYears: [],
    },
    filterDialogOpen: false,
  };