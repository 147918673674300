import React from "react";
import { Box, Stack, Divider } from "@mui/material";

/*This is a styled form template that will take an array of panels and display them.
 it also takes a buttonAction and buttonText. It exists to keep admin form themeing consistent easily*/
export default function FrameworkForm(props) {
  return (
    <Box
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box
        style={{
          margin: "0px 20px 0px 20px",
          minWidth: "320px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack
          spacing={1}
          style={{ width: "100%" }}
          divider={<Divider style={{ width: "100%" }} />}
        >
          {props.panels.map((Panel, index) => {
            return (
              <React.Fragment key={index + "panel"}>{Panel}</React.Fragment>
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
}
