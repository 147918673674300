import React from 'react';
import {FormGroup, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterOption from './Option';

export default function FilterSection(props) {
  return (
    <Accordion elevation={0} defaultExpanded={true} disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={"filter-"+props.title+"-content"}>
        <Typography variant="subtitle2">
          {props.title}(s)
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormGroup row>
          {props.filterOptions.map((filterOption,i)=>{
            return(
              <FilterOption
                key={"flter-option-"+props.name+i}
                filterState={props.filterState}
                filterOption={filterOption}
                filterType={props.filterType}
                handleFilterChange={props.handleFilterChange}/>
            )
          })}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  )
}