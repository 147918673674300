import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL+'/api',
    withCredentials: true,
})

export const getTwitterItems = (payload) => api.post(`/gallery/gettwitteritems`, payload);
export const getTweet = (payload) => api.post(`/gallery/gettweet`, payload)

//Gallery
    export const getGalleryFilters = (payload) => api.get(`/gallery/filters`, payload);
    //Items
    export const createGalleryItem = (payload) => api.post(`/gallery/item/create`, payload);
    export const updateGalleryItem = (payload) => api.post(`/gallery/item/update`, payload);
    export const deleteGalleryItem = (id) => api.post(`/gallery/item/delete`, {id: id});


export const getAllGalleryImages = (payload) => api.get(`/gallery/images`, payload);
export const updateGalleryImageById = (id, payload) => api.put(`/gallery/image/${id}`, payload);
//export const deleteGalleryItem = (id) => api.post(`/gallery/item/delete`, {id: id});
export const getGalleryImageById = (id) => api.get(`/gallery/image/${id}`);
export const getAdminGalleryImageById = (payload) => api.post(`/gallery/adminimage/`, payload);
export const getSomeGalleryImages = (payload) => api.post('/gallery/someimages', payload);
export const determinePageToLoad = (payload) => api.post('/gallery/determinepagetoload', payload);
export const determineNextImage = (payload) => api.post('/gallery/determinenextimage', payload);
export const determinePreviousImage = (payload) => api.post('/gallery/determinepreviousimage', payload);
export const activateGalleryItems = (payload) => api.post(`/gallery/item/activate`, payload);
export const deactivateGalleryItems = (payload) => api.post(`/gallery/item/deactivate`, payload);


export const getGalleryImageCount = () => api.get('/gallery/imagecount');

export const getAdminGalleryImages = payload => api.post('/gallery/admingalleryimages', payload)

export const getGalleryYears = () => api.get('/gallery/years');

//Gallery Mediums
export const getGalleryMediums = () => api.get('/gallery/mediums');
export const getGalleryMediumsAdmin = () => api.get('/gallery/mediumsadmin');
export const getGalleryMediumIds = () => api.get('/gallery/mediumids');
export const insertGalleryMedium = payload => api.post(`/gallery/medium`, payload);
export const setDefaultMediums = payload => api.post(`/gallery/setdefaultmediums`, payload);
export const getGalleryMediumById = (id) => api.get(`/gallery/medium/${id}`);
export const updateGalleryMediumById = (id, payload) => api.put(`/gallery/medium/${id}`, payload);


//Shop Categories
export const getShopCategories = () => api.get('/store/categories');
export const getShopCategoriesAdmin = () => api.get('/store/categoriesadmin');
export const insertShopCategory = payload => api.post(`/store/category`, payload);

//Shop Items
export const insertStoreItem = payload => api.post(`/store/item`, payload);
export const getAllStoreItems = payload => api.post('/store/items', payload);
export const updateStoreItemById = (id, payload) => api.put(`/store/item/${id}`, payload);
export const deleteStoreItemById = id => api.delete(`/store/item/${id}`);
export const getStoreItemById = id => api.get(`/store/item/${id}`);
export const getAdminStoreItemById = id => api.get(`/store/adminitem/${id}`);

export const getStoreItemCount = () => api.get('/store/itemcount');

export const getAdminStoreItems = payload => api.post('/store/adminstoreitems', payload);
export const updateDynamicProductOptions = payload => api.post('/store/updateDynamicProductOptions', payload);
export const getListOfProductsBasedOnImage = payload => api.post('/store/getListOfProductsBasedOnImage', payload)

export const handleDynamicDisconnect = payload => api.post('/store/handleDynamicDisconnect', payload);

    //Shop Item Product Images
    export const deleteStoreItemProductImage = (payload) => api.post(`/store/item/deleteproductimage/`, payload)
    export const insertStoreItemProductImage = (payload) => api.post(`store/item/productimage/`, payload)

//Shopping Cart
export const addItemToCart = payload => api.post(`/store/cart/addItemToCart`, payload);
export const removeItemFromCart = payload => api.post(`/store/cart/removeItemFromCart`, payload);
export const adjustItemQuantityInCart = payload => api.post(`/store/cart/adjustItemQuantityInCart`, payload);
export const fetchCheckout = payload => api.post(`/store/cart/fetchCheckout`, payload);
export const createStripePrice = (id, payload) => api.post(`/store/item/createStripePrice/${id}`, payload);
export const retrieveCheckoutSession = payload => api.post(`/store/cart/retrieveCheckoutSession`, payload);
export const checkoutVerifyAddress = payload => api.post(`/store/checkout/verifyaddress`, payload);
export const checkoutGetShippingRates = payload => api.post(`/store/checkout/getshippingrates`, payload);
export const retrieveCart = () => api.get('/store/cart/retrieveCart');
export const clearCart = () => api.get('/store/cart/clearCart');

//Authentication
//export const adminLogin = (payload) => api.post(`/auth/adminLogin`, payload);
export const loginAdminLocal = (payload) => api.post(`/auth/loginAdminLocal`, payload);
//export const adminProtect = (payload) => api.post(`/auth/adminProtect`, payload);
//export const authGoogle = () => api.get(`/auth/google`);
export const logoutLocal = () => api.get(`/auth/logoutLocal`);
export const registerLocal = () => api.post('/auth/registerAdminLocal');
//export const authGoogleRedirect = () => api.post(`/auth/google/redirect`);
export const checkLogin = () => api.get('/auth/checkLogin');

export const getAvailableDynamicProductsAdmin = () => api.get('/store/getAvailableDynamicProductsAdmin');

const apis = {
    getAdminGalleryImageById,
    getAdminStoreItemById,
    getGalleryFilters,
    updateGalleryItem,
    handleDynamicDisconnect,
    getListOfProductsBasedOnImage,
    updateDynamicProductOptions,
    getAvailableDynamicProductsAdmin,
    createGalleryItem,
    getAdminGalleryImages,
    getAllGalleryImages,
    getSomeGalleryImages,
    updateGalleryImageById,
    deleteGalleryItem,
    getGalleryImageById,
    getGalleryMediums,
    insertGalleryMedium,
    insertStoreItem,
    getGalleryImageCount,
    getShopCategories,
    insertShopCategory,
    getAllStoreItems,
    updateStoreItemById,
    deleteStoreItemById,
    getStoreItemById,
    fetchCheckout,
    retrieveCheckoutSession,
    createStripePrice,
    deleteStoreItemProductImage,
    insertStoreItemProductImage,
    //adminLogin,
    //adminProtect,
    //authGoogle,
    getAdminStoreItems,
    getStoreItemCount,
    checkLogin,
    logoutLocal,
    loginAdminLocal,
    registerLocal,
    determinePageToLoad,
    determineNextImage,
    determinePreviousImage,
    getTwitterItems,
    getTweet,
   // createTweet,
    checkoutVerifyAddress,
    checkoutGetShippingRates,
    addItemToCart,
    removeItemFromCart,
    retrieveCart,
    clearCart,
    adjustItemQuantityInCart,
    getGalleryMediumIds,
    getGalleryMediumsAdmin,
    getShopCategoriesAdmin,
    getGalleryMediumById,
    setDefaultMediums,
    getGalleryYears,
    updateGalleryMediumById,
    activateGalleryItems,
    deactivateGalleryItems
   // authGoogleRedirect
}

export default apis