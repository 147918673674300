import React from "react";
import { Icon } from "@iconify/react";
import {
  IconButton,
  Typography,
  Popper,
  Box,
  Paper,
  Card,
  Stack,
  Divider,
} from "@mui/material";
import closeIcon from "@iconify/icons-mdi/close";
import informationIcon from "@iconify/icons-mdi/information";
import imageFrame from "@iconify/icons-mdi/image-frame";
import { Link } from "react-router-dom";
import InfoPopper from "./InfoPopper";
import ShopPopper from "./ShopPopper";
import { useIsLarge } from "../../Navigation/IsLargeContext.js";
export default function GalleryModalMenu(props) {
  const isLarge = useIsLarge();
  const [popperState, setPopperState] = React.useState(null);
  const menuRef = React.useRef();
  // const arrowRef = React.useRef();
  const handleClick = (whichMenu) => {
    if (popperState === whichMenu) {
      setPopperState(null);
    } else {
      setPopperState(whichMenu);
    }
  };

  return (
    <Paper
      square
      ref={menuRef}
      elevation={24}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "64px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          alignItems: "center",
          maxWidth: "1440px",
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "75%",
          }}
        >
          <Typography
            variant="h5"
            style={{
              color: "#2f2f2f",
              marginLeft: "15px",
              overflowWrap: "anywhere",
              fontSize: isLarge ? "2.5vw" : "5.5vw",
            }}
          >
            {props.image.title}
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "10px",
          }}
        >
          <IconButton
            onClick={() => {
              handleClick("info");
            }}
            color={popperState === "info" ? "secondary" : "primary"}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              padding: "2px",
              margin: "5px",
            }}
          >
            <Icon icon={informationIcon} width="32" height="32" />
          </IconButton>

          <IconButton
            onClick={() => {
              handleClick("shop");
            }}
            color={popperState === "shop" ? "secondary" : "primary"}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              padding: "2px",
              margin: "5px",
            }}
          >
            <Icon icon={imageFrame} width="32" height="32" />
          </IconButton>
          <IconButton
            onClick={props.handleCloseGalleryModal}
            color="primary"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              padding: "2px",
              margin: "5px",
            }}
          >
            <Icon icon={closeIcon} width="32" height="32" />
          </IconButton>
        </div>
      </div>

      <InfoPopper
        menuRef={menuRef}
        popperState={popperState}
        image={props.image}
      />
      <ShopPopper
        menuRef={menuRef}
        popperState={popperState}
        image={props.image}
        handleAddToCart={props.handleAddToCart}
      />
    </Paper>
  );
}
