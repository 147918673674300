import React from 'react';
import { Link } from 'react-router-dom';
import {Drawer, List, Divider, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { StorefrontRounded,ImageRounded, CloseRounded, Twitter, InfoRounded } from '@mui/icons-material/';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export default function MenuDrawer(props) {

  return (
    <Drawer anchor={"right"} open={props.open} onClose={props.toggleDrawer(false)}  
        role="presentation"
        onClick={props.toggleDrawer(false)}
        onKeyDown={props.toggleDrawer(false)}>
        <List 
        style={{width:"20rem"}}>
            <ListItem  onClick={props.toggleDrawer(false)} button key={"Gallery"} 
            style={{justifyContent: "flex-end"}} >
                <ListItemIcon style={{color: "black", justifyContent:"flex-end"}}>
                    <CloseRounded style={{width: "2em", height: "2em"}}/>
                </ListItemIcon>
            </ListItem>
            <Link to="/gallery">
                <ListItem button key={"Gallery"} >
                    <ListItemIcon style={{color: "black"}}>
                        <ImageRounded style={{width: "2em", height: "2em"}}/>
                    </ListItemIcon>
                    <ListItemText primary={"GALLERY"} secondary={"Lots of images to look through."}/>
                </ListItem>
            </Link>
            <Divider/>
            <Link to="/shop">
                <ListItem button key={"Shop"} >
                    <ListItemIcon style={{color: "black"}}>
                        <StorefrontRounded style={{width: "2em", height: "2em"}}/>
                    </ListItemIcon>
                    <ListItemText  primary={"SHOP"} secondary={"Prints, stickers, buttons and more."}/>
                </ListItem>
            </Link>
            <Divider/>
            <Link to="/about">
                <ListItem button key={"About"} style={{fontSize:"2em"}}>
                    <ListItemIcon style={{color: "black"}}>
                        <InfoRounded style={{width: "2em", height: "2em"}}/>
                    </ListItemIcon>
                    <ListItemText primary={"ABOUT"} secondary={"Information and history."}/>
                </ListItem>
            </Link>
            <Divider/>
            {(props.loggedIn)?<React.Fragment>
                <Link to="/admin">
                    <ListItem button key={"Admin"} style={{fontSize:"2em"}}>
                        <ListItemIcon style={{color: "black"}}>
                            <AdminPanelSettingsIcon style={{width: "2em", height: "2em"}}/>
                        </ListItemIcon>
                        <ListItemText primary={"ADMIN"} secondary={"Admin section."}/>
                    </ListItem>
                </Link>
                <Divider/>
            </React.Fragment>:null}
            
            <a href="https://twitter.com/errorbytrial" target="_blank" rel="noopener noreferrer" className="appBarButton">
                <ListItem button key={"Twitter"} >
                    <ListItemIcon style={{color: "black"}}>
                    <Twitter style={{color:"black",width: "2em", height: "2em"}}/>
                    </ListItemIcon>
                </ListItem>
            </a>
        </List>
    </Drawer>
  );
}