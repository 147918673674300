import React from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Box,
  CardActionArea,
  CardContent,
  Card,
} from "@mui/material";
import { useIsLarge } from '../Navigation/IsLargeContext.js';

export default function LandingPanel(props) {
    const isLarge = useIsLarge();
  return (
    <Card
      className="pulse"
      square
      raised
      style={{
        backgroundImage: "url(" + props.panel.image + ")",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <CardActionArea>
        <Link to={props.panel.link}>
          <Box
            style={{
              display: "flex",
              flexDirection: props.infoFlip ? "row-reverse" : "row",
              height: "150px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CardContent
              style={{
                display:"flex",
                flexDirection:"column",
                alignItems: "center",
                justifyContent:"center",
                height: "100%",
                width: "100%",
                backgroundColor: "rgba(50, 50, 50, 0.4)",
              }}
            >
              <Typography
                align="center"
                variant="h2"
                style={{ color: "floralwhite" }}
              >
                {props.panel.title}
              </Typography>
              <Typography
                align="center"
                variant={isLarge ? "body1" : "body2"}
                style={{ color: "floralwhite" }}
              >
                {props.panel.description}
              </Typography>
            </CardContent>
          </Box>
        </Link>
      </CardActionArea>
    </Card>
  );
}
