import React from "react";
import EBTLoading from '../../Navigation/Loading';

export default function GalleryModalImage(props) {
  //const [imageScale, setImageScale] = useState(1);
  const imageScale = 1;
  return (
    (props.loading)
    ?<EBTLoading/>
    :<div className="GalleryModalImageWrapper">
      <img
      src={props.image.src}
      alt={props.image.title}
      // onDrag={imageMove}
      // onWheel={imageZoom}
      style={{
        maxHeight: "100%", 
        maxWidth: "100%", 
        objectFit:"contain", 
        zIndex:"5", 
        transform:"scale("+imageScale+") translate(0px,0px)"}}
      />
    </div>
  )
}