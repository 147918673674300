import React from "react";
import { Stack, Box } from "@mui/material";
import LandingPanel from "./Panel";
import { useIsLarge } from '../Navigation/IsLargeContext.js';


export default function Landing(props) {
  const landingPanels = [
    {
      title: "Gallery",
      description: "View new and archived art across a variety of mediums.",
      link: "/gallery",
      image:
        "https://api.errorbytrial.com/images/galleryimages/635fe3800a9cb63ac2314fcf/635fe3800a9cb63ac2314fcf_400.jpg",
    },
    {
      title: "Shop",
      description: "Purchase prints, stickers, postcards, and more.",
      link: "/shop",
      image:
        "https://api.errorbytrial.com/images/galleryimages/635fe3760a9cb63ac2314fcc/635fe3760a9cb63ac2314fcc_400.jpg",
    },
    {
      title: "About",
      description: "Read the artist bio, company history, and website info.",
      link: "/about",
      image:
        "https://api.errorbytrial.com/images/galleryimages/632e1539824c326ad88f45e2/632e1539824c326ad88f45e2_400.jpg",
    },
  ];

  const isLarge = useIsLarge();

  return (
    <Box style={{ display: "flex", justifyContent: "center" }}>
      <Stack
        spacing={2}
        style={{
          alignItems: isLarge ? "stretch" : null,
          width: isLarge ? null : "100%",
          minWidth: "300px",
          maxWidth: "750px",
          marginTop: "10px",
        }}
      >
        {landingPanels.map((panel, index) => {
          return (
            <LandingPanel
              key={panel.title}
              infoFlip={index % 2 === 0}
              panel={panel}
            />
          );
        })}
      </Stack>
    </Box>
  );
}
