import React, { useState, useEffect } from 'react';
import {  Paper, Typography, Button, Box} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterDialog from './FilterDialog';
import FilterChips from './FilterChips';
import axios from 'axios';
import api from '../../../api';
import { useIsLarge } from '../../Navigation/IsLargeContext.js';


export default function Toolbar(props) {
  const isLarge = useIsLarge();
  const [filterOptions, setFilterOptions] = useState({
    filterMediums: [],
    filterYears: []
  })

  useEffect(
    () => {
      const loadToolbarFilterData = async () => {
        try {
            const response = await api.getGalleryMediums();
            const yearsResponse = await api.getGalleryYears();

            setFilterOptions({
              filterMediums: response.data.data,
              filterYears: yearsResponse.data.data
            });
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log(`call was cancelled`);
          } else {
            throw error;
          }
        }
      }
      loadToolbarFilterData();
    },[]
  );  

  return (
    <div>
      <Paper square style={{display:"flex", padding:"2px", justifyContent:"center",
        width:"100%"}}>
        <Box style={{maxWidth:"1440px", width:"100%",
          marginLeft:"5px", paddingRight:"5px", display:"flex",
          justifyContent: "space-between", alignItems:"center"}}>
          <Button size={isLarge?'medium':'small'} 
            onClick={props.handleFilterDialogToggle} 
            startIcon={<FilterListIcon />}>
            {"Filter"}
          </Button>
          <FilterChips     
            removeFilterParam={props.removeFilterParam}
            filterState={props.currentFilterState}
            filterOptions={filterOptions}/>
          <Typography variant="caption" noWrap style={{minWidth:"min-content"}}>
            {"("+props.totalImageCount+") results"}
          </Typography>  
        </Box>
      </Paper> 
      <FilterDialog
        filterDialogOpen={props.filterDialogOpen}
        handleFilterClear={props.handleFilterClear}
        handleFilterApply={props.handleFilterApply}
        handleFilterDialogToggle={props.handleFilterDialogToggle}
        filterOptions={filterOptions}
        handleFilterChange={props.handlePotentialFilterChange}
        potentialFilterState={props.potentialFilterState}/> 
    </div>
  );
}