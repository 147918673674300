import React from "react";
import Gallery from "react-photo-gallery";
import { Typography, Box } from "@mui/material";
import EBTLoading from "../../Navigation/Loading";
import { useIsLarge } from "../../Navigation/IsLargeContext";

export default function GalleryGrid(props) {
  const { loading, images, navigateToImage } = props;
  const isLarge = useIsLarge();
  const handleImageClick = (event, { index }) => {
   navigateToImage(images[index]._id)
  };
  if (loading) {
    return (
      <Box style={{ height: "450px" }}>
        <EBTLoading />
      </Box>
    );
  }

  if (images.length === 0) {
    return (
      <Box
        style={{ display: "flex", height: "350px", justifyContent: "center" }}
      >
        <Typography variant="h5" style={{ color: "black", marginTop: "25px" }}>
          No results. Try changing filter.
        </Typography>
      </Box>
    );
  }

  return (
    <Gallery
      margin={isLarge ? 5 : 2}
      photos={images}
      onClick={handleImageClick}
      targetRowHeight={isLarge ? 400 : 150}
    />
  );
}
