import React from 'react';
import { Chip, Box } from '@mui/material'

export default function FilterChips(props) {

  const chipLabel = (selectedOption, filterOptions) =>{
    const match = filterOptions.find((option)=>{
      return option._id === selectedOption
    });
    if(match){
      return(
        match.name
      )
    }else{
      return(
        ''
      )
    }
  }
  
  return (
    <Box style={{display:"flex", width:"100%", justifyContent:"flex-start", flexWrap:"wrap"}}>
     {props.filterState.filterMediums.map(mediumFilter=> {
      return(
        <Chip key={mediumFilter+"filter-chip"}
          style={{marginRight:"2px", backgroundColor:"darkgrey"}} 
          variant="outlined" 
          size={"small"} 
          label={chipLabel(mediumFilter, props.filterOptions.filterMediums)}
          onDelete={()=>{props.removeFilterParam("filterMediums",mediumFilter)}} />
      )
     })}
      {props.filterState.filterYears.map(yearFilter=> {
      return(
        <Chip key={yearFilter+"filter-chip"}
          style={{marginRight:"2px", backgroundColor:"darkgrey"}} 
          variant="outlined" 
          size={"small"} 
          label={chipLabel(yearFilter, props.filterOptions.filterYears)}
          onDelete={()=>{props.removeFilterParam("filterYears",yearFilter)}} />
      )
     })}
    </Box>  
  )
}