import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material'

export default function FilterPanelCheckbox(props) {
  return(
    <FormControlLabel
      label={props.filterOption.name}
      control={
        <Checkbox
          checked={props.filterState.find(el => el === props.filterOption._id)?true:false}
          onChange={()=>{props.handleFilterChange(props.filterType,props.filterOption._id)}}
        />
      }
    />
  )
}