import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Typography, IconButton, Badge } from "@mui/material";
import { MenuRounded, ShoppingCartRounded } from "@mui/icons-material";

export default function AppBarSmall(props) {
  return (
    <AppBar position="fixed" className="appBar">
      <Toolbar style={{ minHeight: "64px", justifyContent: "space-between" }}>
        <Link to="/" style={{ color: "white" }}>
          <Typography variant="h4" style={{ fontFamily: "Teko" }}>
            Error By Trial
          </Typography>
        </Link>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link to="/shop/checkout" style={{ color: "white" }}>
            <Badge
              badgeContent={props.numberOfItemsInCart}
              overlap="circular"
              showZero
              sx={{
                "& .MuiBadge-badge": {
                  right: -3,
                  top: 13,
                  border: `2px solid white`,
                  padding: "0 4px",
                },
              }}
            >
              <ShoppingCartRounded />
            </Badge>
          </Link>
          <IconButton
            style={{ color: "white", marginLeft: "15px" }}
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={props.toggleDrawer(true)}
          >
            <MenuRounded />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}
