import React, { useEffect, useReducer, useState, useCallback } from "react";
import {
  Outlet,
  useSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import api from "../../api";
import axios from "axios";
import { Box } from "@mui/material";
import Toolbar from "./Toolbar";
import GalleryGrid from "./Grid";
import PaginationFooter from "../Shared/PaginationFooter";
import galleryReducer from "./galleryReducer";
import galleryInitialState from "./galleryInitialState";

export default function Gallery() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(galleryReducer, galleryInitialState);
  const [savedURL, setSavedURL] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const handleFilterDialogToggle = () => {
    dispatch({
      type: "filter_dialog_toggle",
    });
  };

  const handleFilterApply = () => {
    setSearchParams({
      ...state.potentialFilterState,
      page: galleryInitialState.page,
      limit: state.limit,
    });
    dispatch({
      type: "filter_dialog_toggle",
    });
  };

  const handleRemoveFilterParam = (filterType, value) => {
    const newFilterState = state.filterState[filterType].filter(
      (c) => c !== value
    );
    setSearchParams({
      ...state.filterState,
      [filterType]: newFilterState,
      page: galleryInitialState.page,
    });
  };

  const handlePotentialFilterChange = (filterType, value) => {
    const newFilterState = state.potentialFilterState[filterType].includes(
      value
    )
      ? state.potentialFilterState[filterType].filter((c) => c !== value)
      : [...state.potentialFilterState[filterType], value];
    dispatch({
      type: "set_potential_filter",
      filter: { ...state.potentialFilterState, [filterType]: newFilterState },
    });
  };

  const handleFilterClear = () => {
    setSearchParams({ page: galleryInitialState.page, limit: state.limit });
    dispatch({
      type: "filter_dialog_toggle",
    });
  };

  const handleSetPage = (page) => {
    searchParams.set("page", page);
    setSearchParams(searchParams);
  };

  const handleSetLimit = (limit) => {
    searchParams.set("limit", limit);
    setSearchParams(searchParams);
  };

  const getPageData = (searchParams) => {
    const page = searchParams.get("page") || galleryInitialState.page;
    const limit = searchParams.get("limit") || galleryInitialState.limit;
    const filterMediums = searchParams.getAll("filterMediums");
    const filterYears = searchParams.getAll("filterYears");

    return { page, limit, filterMediums, filterYears };
  };

  const navigateToImage = (imageId) => {
    setSavedURL(location.search);
    setModalOpen(true);
    navigate(`/gallery/image/${imageId}`);
  };

  const handleCloseGalleryModal = () => {
    if (savedURL !== "") {
      navigate("/gallery/" + savedURL);
    } else {
      navigate("/gallery");
    }
    setModalOpen(false);
  };

  const handleOpenGalleryModal = () => {
    setModalOpen(true);
  };

  const fetchData = useCallback(
    async (pageData) => {
      if (!pageData || typeof pageData !== "object") {
        console.log("Invalid page data.");
        return;
      }
      dispatch({ type: "set_loading_true" });
      try {
        const {
          data: { success, galleryImages, totalCount },
        } = await api.getSomeGalleryImages(pageData);
        success
          ? dispatch({
              type: "set_images_loaded",
              images: galleryImages,
              totalImageCount: totalCount,
            })
          : dispatch({
              type: "set_images_loaded",
              images: [],
              totalImageCount: 0,
            });
      } catch (error) {
        if (error.response) {
          console.log(error.response);
        }
        if (axios.isCancel(error)) {
          console.log("Call was cancelled");
        } else {
          console.error(error);
        }
      }
    },
    [dispatch]
  );

  const updatePageData = useCallback(
    (data) => {
      const { filterMediums, filterYears, limit, page } = data;
      dispatch({
        type: "set_new_filter",
        filter: { filterMediums, filterYears },
        limit,
        page,
      });
      fetchData({ filterMediums, filterYears, limit, page });
    },
    [dispatch, fetchData]
  );

  useEffect(() => {
    if (modalOpen) {
      if (!state.firstLoadComplete) {
        const incomingPageData = getPageData(searchParams);
        updatePageData(incomingPageData);
      }
    } else {
      const { page, limit, filterMediums, filterYears } =
        getPageData(searchParams);
      if (
        page !== state.page ||
        limit !== state.limit ||
        filterMediums.join(",") !== state.filterState.filterMediums.join(",") ||
        filterYears.join(",") !== state.filterState.filterYears.join(",")
      ) {
        updatePageData({ page, limit, filterMediums, filterYears });
      }else if (!state.firstLoadComplete) {
        const incomingPageData = getPageData(searchParams);
        updatePageData(incomingPageData);
      }
    }
  }, [searchParams, state, modalOpen, updatePageData]);

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <Toolbar
        totalImageCount={state.totalImageCount}
        currentFilterState={state.filterState}
        potentialFilterState={state.potentialFilterState}
        removeFilterParam={handleRemoveFilterParam}
        handlePotentialFilterChange={handlePotentialFilterChange}
        handleFilterApply={handleFilterApply}
        handleFilterClear={handleFilterClear}
        handleFilterDialogToggle={handleFilterDialogToggle}
        filterDialogOpen={state.filterDialogOpen}
      />
      <Box style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: "1440px",
          }}
        >
          <GalleryGrid
            navigateToImage={navigateToImage}
            loading={state.loading}
            images={state.images}
          />

          <PaginationFooter
            totalItems={state.totalImageCount}
            handleSetPage={handleSetPage}
            handleSetLimit={handleSetLimit}
            page={state.page}
            limit={state.limit}
          />
        </Box>
      </Box>
      <Outlet
        context={{
          handleOpenGalleryModal: handleOpenGalleryModal,
          handleCloseGalleryModal: handleCloseGalleryModal,
          mediumFilter: state.filterState.filterMediums,
          yearsFilter: state.filterState.filterYears,
        }}
      />
    </Box>
  );
}
