export default function galleryReducer(state, action) {
  switch (action.type) {
    case "filter_dialog_toggle": {
      return {
        ...state,
        filterDialogOpen: !state.filterDialogOpen,
      };
    }
    case "set_loading_true": {
      return {
        ...state,
        loading: true,
      };
    }
    case "set_images_loaded": {
      return {
        ...state,
        images: action.images,
        totalImageCount: action.totalImageCount,
        loading: false,
      };
    }
    case "set_new_filter": {
      return {
        ...state,
        firstLoadComplete: true,
        filterState: action.filter,
        potentialFilterState: action.filter,
        limit: action.limit,
        page: action.page,
        backgroundLoad: action.backgroundLoad
      };
    }
    case "set_potential_filter": {
      return {
        ...state,
        potentialFilterState: action.filter,
      };
    }
    default:
      throw Error("Unkown action: " + action.type);
  }
}