import React from "react";
import { Paper, Button } from "@mui/material";
import { Link } from 'react-router-dom';

export default function EBTAdminToolBar(props) {

  return (
    <Paper square className="secondaryToolBar">
      <Link to='gallery'>
        <Button className="secondaryToolBarButton">
          Gallery Admin
        </Button>
      </Link>
      <Link to='store'>
        <Button className="secondaryToolBarButton">
          Store Admin
        </Button>  
      </Link>

        <Button onClick={()=>{props.logoutAdmin()}} className="secondaryToolBarButton">
          LogOut
        </Button>  

    </Paper>
  )
}