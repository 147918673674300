import React,{ useState  } from "react";
import api from '../../api';
import { TextField, Button } from "@mui/material";

export default function EBTAdminToolBar(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmitCredentials = async () => {
    const payload = {
      username: username,
      password: password
    }

    await api.loginAdminLocal(payload).then(res => {
      if(res.data.auth === true)
        props.loginAdmin();
    })
  }

  // const handleLogout = async () => {
  //   await api.logoutLocal().then(res => {
  //     console.log(res.status);
  //   })
  // }

  // const handleRegister = async () => {
  //   await api.registerLocal().then(res => {
  //     console.log(res.status);
  //   })
  // }

  const handleUsernameChange = (event) => {
    const username = event.target.value;
    setUsername(username);
  }

  const handlePasswordChange = (event) => {
    const password = event.target.value;
    setPassword(password);
  }

  return (
    (props.loggedIn)
    ?<div>wtf</div>
    :<div style={{marginTop: "15px", display: "flex", flexDirection:"column", alignItems:"center"}}>     
      <TextField id="outlined-basic-user"
        type="password"
        value={username} 
        label="User" 
        variant="outlined" 
        onChange={handleUsernameChange} 
        />  
      <TextField id="outlined-basic-password"
        type="password"
        value={password} 
        label="Password" 
        variant="outlined" 
        onChange={handlePasswordChange} 
        />
      <div>
        <Button type="submit" onClick={handleSubmitCredentials}>Submit</Button>
      </div>
    </div>
  )
}