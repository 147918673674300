import React, {useEffect, useState} from "react";
import { Outlet } from "react-router-dom";
import api from '../../api';
import axios from 'axios';
import AdminToolBar from './AdminToolBar';
import AdminLogin from './AdminLogin';

function Admin(props) {
  const [checkingLogin, setCheckingLogin] = useState(true);
  //const [loggedIn, setLoggedIn] = useState(false);
  const loginAdmin = () => {
    props.setLoggedIn(true);
  }

  const logoutAdmin = () => {
    api.logoutLocal();
    props.setLoggedIn(false);
  }

  useEffect(
    () => {
      const checkLogin = async () => {
        try {
          const response = await api.checkLogin();
          setCheckingLogin(false);
          if(response.data.auth){
            props.setLoggedIn(true);
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            setCheckingLogin(false);
            console.log(`call was cancelled`);
          } else {
            setCheckingLogin(false);
            throw error;
          }
        }
      };
      checkLogin();
    },[]
  );

  return (
    (checkingLogin)
    ?null
    :<div>
      <div>    
        {(props.loggedIn)
        ?<AdminToolBar logoutAdmin={logoutAdmin}/>
        :null}
        <div className="contentRoot">
          {(props.loggedIn)
          ?<div style={{display:"flex", justifyContent:"center", margin:"10px"}}>
            <Outlet/></div>
          :<AdminLogin loginAdmin={loginAdmin} loggedIn={props.loggedIn}/>}
        </div>
      </div>
    </div>
  )
}

export default Admin;